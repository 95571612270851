<template>
  <section class="banner">
    <hgroup>
      <h1>
        Trousdale County Christmas Parade
      </h1>
      <h2>Saturday Dec. 8 @ 10:00 am</h2>
    </hgroup>
    <p>
      View the Christmas Parade Lineup!
    </p>
    <p>
      <router-link to="/parade">Parade Lineup</router-link>
    </p>
  </section>
</template>
<style lang=scss scoped>
    $font-color: paleGreen;
    $banner-background-color:  transparentize(red, 0.3);
    $banner-text-color: white;
    $banner-link-color: paleGreen;
    $banner-link-hover-color: lighten(paleGreen, 10%);
    $title-text-color: #f5d8a3;
    $title-font: 'Heebo', sans-serif;

   .banner {
    background-color: $banner-background-color;
    color: $title-text-color;
    font-size: 18px;
    line-height: 1.5;
    letter-spacing: 1px;
    margin-top: 48px;
    padding: 16px 8px;

    a {
      color: $banner-link-color;

      &:hover {
        color: $banner-link-hover-color
      }
    }

    hgroup {
      h1 {
        color: $banner-text-color;
        font-family: $title-font;
        font-size: 20px;
        margin-bottom: 8px;
        text-align: center;
        text-decoration: underline;
      }

      h2 {
        color: $font-color;
        font-size: 16px;
        font-style: italic;
        margin: 0 auto;
        text-align: center;
      }
    }

    p {
      color: $banner-text-color;
      margin: 0 auto 16px;
      text-align: center;
    }
  }
</style>
