<template>
  <main class="hero-section">
    <section class="titlegroup">
      <h1 class="title title-item">Hartsville-Trousdale County</h1>
      <h1 class="subtitle title-item">Chamber of Commerce</h1>
      <h1 class="tagline title-item">Friendly people... Scenic Countryside... Make it your home</h1>
      <div class="call-to-action">
        <figure class="join cta">
          <router-link
            to="/membership"
            class="join-button">
            JOIN
          </router-link>
        </figure>

        <figure class="email cta">
          <router-link
            to="/maillist"
            class="email-button">
            GET EMAILS
          </router-link>
        </figure>
      </div>
      <template v-if="notExpired('12-9-2018')">
        <ParadeAd />
      </template>
    </section>

    <CivilWarBanner/>
  </main>
</template>
<script>

import ParadeAd from './ParadeAd.vue';
import CivilWarBanner from '../sections/CivilWarBannerComponent.vue';
import moment from 'moment';

export default {
  components: {
    ParadeAd,
    CivilWarBanner
  },
  methods: {
    notExpired: function (expireDate) {
      const today = moment();
      return today.isBefore(expireDate, 'day');
    }
  }
};
</script>
<style lang=scss scoped>
  $header-font: 'Heebo', sans-serif;
  $body-font: 'Roboto Slab', serif;
  $text-color: #ffffff;
  $cta-text-color: lighten(#f5d8a3, 3%);
  $cta-border-color: #f5d8a3;
  $cta-background-color: #000000;
  $cta-font: 'Heebo', sans-serif;
  $cta-hover-color: #ffffff;
  $title-text-color: #f5d8a3;

  .hero-section {
    align-items: center;
    background-image: url(/public/imgs/courthousebackground.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    height: 600px;
    justify-items: center;
    position: relative;

    &:before {
      background-color: black;
      content: "";
      height: 100%;
      opacity: 0.5;
      position: absolute;
      width: 100%;
    }
  }

  .titlegroup {
    display: inline-block;
    padding-left: 8px;
    position: relative;
  }

  .title-item {
    color: $text-color;
    letter-spacing: 1.1px;
  }

  .title {
    color: $title-text-color;
    font-family: $header-font;
    font-style: italic;
  }

  .subtitle {
    font-family: $header-font;
    font-size: 24px;
    font-weight: 900;
  }

  .tagline {
    font-family: $header-font;
    font-size: 14px;
    font-style: italic;
    font-weight: 400;
    margin: 8px 0 32px;
  }

  .call-to-action {
    color: $cta-text-color;
    font-family: $cta-font;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 2px;
    margin-top: 16px;
    text-align: center;
  }

  .cta {
    border: 1px solid $cta-border-color;
    display: inline-block;
    position: relative;
    transition: .8s ease;
    z-index: 0;

    &::before {
      background-color: $cta-background-color;
      content: "";
      height: 100%;
      left: 0;
      opacity: 0;
      position: absolute;
      transition: .8s ease;
      top: 0;
      width: 100%;
      z-index: -1;
    }

    &:hover {

      &::before {
        opacity: 0.5;
      }
    }

    &:first-child {
      margin-right: 1em
    }
  }

  .email-button {
    box-sizing: border-box;
    color: $cta-text-color;
    display: block;
    height: 100%;
    padding: 16px;
    text-decoration: none;
    width: 100%;
  }

  .join-button {
    box-sizing: border-box;
    color: $cta-text-color;
    display: block;
    height: 100%;
    padding: 16px;
    text-decoration: none;
    width: 100%;
  }

  @media screen and ( min-width: 768px) {
    .title {
      font-size: 32px;
    }

    .subtitle {
      font-size: 64px;
    }

    .tagline {
      font-size: 20px;
    }

    .cta {
      font-size: 20px;
    }
  }

</style>
