<template>
  <main class="history-section">
    <div class="content-wrapper">
      <h1>
        Hartsville-Trousdale County History
      </h1>
      <section class="historical-society-link">
        <a href="https://trousdalehistory.org">Historical Society Website</a>
      </section>
      <section class="history-paragraph">
        <p>
          The first settlers arrived in 1797 when the family of James Hart settled along the west bank of the West Fork of Goose Creek and the family of Charles Donoho settled along Goose Creek’s east bank.
        </p>

        <p>
          James Hart established Hart’s Ferry on the Cumberland River. Hartsville was officially recognized as a town in 1817. Donoho’s Mill on the east bank of Goose Creek became known as Damascus. Damascus merged with Hartsville in 1840 when Hartsville officially incorporated. Hartsville is the seat of Trousdale County.

        </p>

        <p>
          Trousdale County was formed in 1870 from parts of Macon, Smith, Sumner and Wilson counties. State law required that a horse and rider must be able to travel it to the county seat and return home in one day. The rugged terrain in the area necessitated the creation of a new county. Trousdale County is also known as Hartsville/Trousdale County and shares a consolidated “metro” government with the city of Hartsville.
        </p>

        <p>
          Trousdale County has a land area of just 117 square miles making it Tennessee’s smallest county. Farming and livestock characterize this largely rural area.
        </p>
      </section>
      <section class="tagline">
        <p>
          Check out the
          <a
            href="https://trousdalehistory.org"
            class="inline">
            Historical Society site
          </a>for more on Trousdale County History.
        </p>
      </section>
      <section class="civil-war-tour">
        If your visiting, see the Battle of Hartsville - Civil War driving tour!
        <figure>
          <a href="https://drive.google.com/open?id=184CQMG9bDwpu_PfOZb66__F5j-aTyoX_&usp=sharing">
            Tour >>
          </a>
        </figure>
      </section>
    </div>
  </main>
</template>
<style lang=scss scoped>
  $history-section-background-color: #ffffff;
  $history-section-text-color: #274159;
  $history-font: 'Heebo', sans-serif;
  $link-hover-color: #f5d8a3;
  $link-color: lighten(#274159, 20%);
  $civil-war-tour-background-color: lighten(#f5d8a3, 10%);

  main {
    background-color: $history-section-background-color;
    line-height: 1.5;
    min-height: 500px;
    padding: 32px 0;
  }

  h1 {
    color: $history-section-text-color;
    display: relative;
    font-family: $history-font;
    font-size: 32px;
    font-weight: 900;
    letter-spacing: 1.5px;
    margin-bottom: 16px;
    text-align: center;
    z-index: 1;
  }

  a {
    color: $link-color;
    display: block;
    font-style: italic;
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 8px;
    text-decoration: none;

    &:hover {
      color: $link-hover-color;
      text-decoration: underline;
    }

    &.inline {
      display: inline;
    }
  }

  .content-wrapper {
    margin: auto;
    max-width: 1014px;

    h1 {
      padding: 8px;
    }
  }

  .historical-society-link {
    padding-left: 48px;
  }

  .history-paragraph {
    color: $history-section-text-color;
    font-family: $history-font;

    p {
      margin-bottom: 32px;
      padding-left: 16px;
      padding-right: 16px;
      text-indent: 32px;
    }
  }

  .tagline {
    color: $history-section-text-color;
    font-family: $history-font;
    margin-bottom: 16px;
    text-align: center;
  }

  .civil-war-tour {
    background-color: $civil-war-tour-background-color;
    font-family: $history-font;
    color: $history-section-text-color;
    padding: 16px 8px 0;
    padding-bottom: 8px;
    text-align: center;

    a {
      display: inline-block;
      margin-top: 16px;
    }
  }

  @media screen and (min-width: 768px) {
    main {
      font-size: 20px;
    }
  }
</style>
