<template>
  <footer class="footer-section">
    <div class="grid-frame">
      <section class="address">
        <div class="name-group">
          <p class="address-item name">HARTSVILLE-TROUSDALE COUNTY</p>
          <p class="address-item name2"> CHAMBER OF COMMERCE</p>
        </div>
        <p class="address-item street"></p>
        <p class="address-item street"></p>
        <p class="address-item phone">P: 615.509.9531</p>
        <p class="address-item email">drgraden@hartsvilletrousdale.com</p>
      </section>
    </div>
    <section class="legal">
      <p class="copyright">
        Copyright &copy; 2018. All Rights Reserved.
      </p>
      <p class="developer">
        Site developed by:
        <span class="developer-name">
          Kent Moreland Web Development
        </span>
      </p>
    </section>
  </footer>
</template>

<style lang=scss scoped>
  $legal-font: 'Heebo', sans-serif;
  $footer-text-font: 'Heebo', sans-serif;
  $footer-background-color: #274159;
  $footer-text-color: #ffffff;
  $developer-text-color: transparentize(#ffffff, 0);
  $developer-highlight-text-color: transparentize(#f5d8a3, 0.3);

  .footer-section {
    box-sizing: border-box;
    align-items: end;
    background-color: $footer-background-color;
    color: $footer-text-color;
    display: grid;
    grid-template-rows: 1fr;
    line-height: 1.5;
    min-height: 175px;
    padding: 8px;
  }
  .grid-frame {
    align-items: center;
    display: flex;
    justify-content: center
  }

  .address {
    display: inline-block;
    font-family: $footer-text-font;
    font-size: 12px;
    font-weight: 300;
    letter-spacing: 1px;
    line-height: 1.5;
    width: 225px;

    .name {
      font-weight: 900;
    }

    .name2 {
      font-weight: 900;
    }

  }

  .address-item {
    margin-bottom: 0;
  }

  .legal {
    font-size: 12px;
    font-family: $legal-font;
    font-weight: 300;
    justify-self: center;
    padding-top: 32px;
    width: 225px;

    .copyright {
      margin-bottom: 0;
    }

    .developer {
      margin-bottom: 0;
    }
  }

  .developer {
    color: $developer-text-color;
  }

  .developer-name {
    color: $developer-highlight-text-color;
    display: block;
  }

  @media screen and (min-width: 768px) {
    .legal {
      align-self: center;
      padding-top: 0;
    }
    .grid-frame {
      align-self: center;
    }
    .footer-section {
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr;
    }
  }
</style>
