import { createRouter, createWebHistory } from 'vue-router';
import HomePage from '../components/Home/Home.vue';

const routes = [
  {
    path: '/',
    component: HomePage,
    meta: {
      title: 'HTC Chamber | Home'
    }
  },
  {
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ '../components/About/About.vue'),
    meta: {
      title: 'HTC Chamber | About'
    }
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import(/* webpackChunkName: "contact" */ '../components/Contact/Contact.vue'),
    meta: {
      title: 'HTC Chamber | Contact us'
    }
  },
  {
    path: '/membership',
    name: 'membership',
    component: () => import(/* webpackChunkName: "membership" */ '../components/Membership/Membership.vue'),
    meta: {
      title: 'HTC Chamber | Join'
    }
  },
  {
    path: '/welcome',
    name: 'welcome',
    component: () => import(/* webpackChunkName: "welcome" */ '../components/Welcome/Welcome.vue'),
    meta: {
      title: 'HTC Chamber | Welcome'
    }
  },
  {
    path: '/business',
    name: 'business',
    component: () => import(/* webpackChunkName: "business" */ '../components/Business/Business.vue'),
    meta: {
      title: 'HTC Chamber | Business'
    }
  },
  {
    path: '/community',
    name: 'community',
    component: () => import(/* webpackChunkName: "community" */ '../components/Community/Community.vue'),
    meta: {
      title: 'HTC Chamber | Community'
    }
  },
  {
    path: '/maillist',
    name: 'maillist',
    component: () => import(/* webpackChunkName: "maillist" */ '../components/Maillist/Maillist.vue'),
    meta: {
      title: 'HTC Chamber | Mail list'
    }
  },
  {
    path: '/calendar',
    name: 'calendar',
    component: () => import(/* webpackChunkName: "calendar" */ '../components/Calendar/CalendarComponent.vue'),
    meta: {
      title: 'HTC Chamber | Community Calendar'
    }
  },
  {
    path: '/event',
    name: 'event',
    component: () => import(/* webpackChunkName: "event" */ '../components/Calendar/sections/EventComponent.vue'),
    meta: {
      title: 'HTC Chamber | Calendar Event'
    }
  },
  {
    path: '/addevent',
    name: 'addEvent',
    component: () => import(/* webpackChunkName: "addEvent" */ '../components/Calendar/AddEvent/AddEvent.vue'),
    meta: {
      title: 'HTC Chamber | Submit an Event'
    }
  },
  {
    path: '/leadershiptrousdale',
    name: 'leadershipTrousdale',
    component: () => import(/* webpackChunkName: "leadershipTrousdale" */ '../components/LeadershipTrousdale/LeadershipTrousdale.vue'),
    meta: {
      title: 'HTC Chamber | Leadership Trousdale'
    }
  },
  {
    path: '/battleofhartsville',
    name: 'battleOfHartsville',
    component: () => import(/* webpackChunkName "battleOfHartsville" */ '../components/CivilWar/civil-war.vue'),
    meta: {
      title: 'HTC Chamber |  Civil War Tour'
    }
  },
  {
    path: '/admin',
    name: 'admin',
    component: () => import(/* webpackChunkName: "admin" */ '../components/Administrator/Administrator.vue'),
    meta: {
      title: 'HTC Chamber | Admin'
    }
  },
  {
    path: '/hotelStudy',
    name: 'hotelStudy',
    component: () => import(/* webpackChunkName: "hotelStudy" */ '../components/HotelStudy/HotelStudy.vue'),
    meta: {
      title: 'HTC Chamber | Hotel Study'
    }
  },
  {
    path: '/meetings',
    name: 'meetings',
    component: () => import(/* webpackChunName: "meetings" */ '../components/Meetings/Meetings.vue'),
    meta: {
      title: 'HTC Chamber | Chamber Meetings'
    }
  },
  {
    path: '/jobs',
    name: 'jobs',
    component: () => import(/* webpackChunkName: "jobs" */ '../components/Jobs/Jobs.vue'),
    meta: {
      title: 'HTC Chamber | Jobs Board'
    }
  },
  {
    path: '/jobs/:id',
    name: 'job',
    component: () => import(/* webpackChunkName: "job" */ '../components/Jobs/sections/JobPage.vue'),
    meta: {
      title: 'HTC Chamber | Job Post'
    }
  },
  {
    path: '/:catchAll(.*)',
    name: 'notFound',
    component: () => import(/* webpackChunkName: "notFound" */ '../components/NotFound/NotFoundPage.vue'),
    meta: {
      title: '404 | Not Found'
    }
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach((to, from, next) => {
  const titleText = document.getElementsByTagName('title')[0];
  titleText.innerHTML = to.meta.title;
  next();
});

export default router;
