<template>
  <main
    :class="{open: menuOpen}"
    class="mobile-nav-list">
    <ul class="navlist">
      <li
        class="navlist-item"
        @click="toggleMenu">
        <router-link
          to="/welcome"
          class="navlist-item-link">
          WELCOME
        </router-link>
      </li>

      <li
        class="navlist-item"
        @click="toggleMenu">
        <router-link
          to="/community"
          class="navlist-item-link">
          COMMUNITY
        </router-link>
        <figure class="sublist-item">
          <router-link
            to="/calendar"
            class="sublist-link">
            Calendar
          </router-link>
        </figure>
        <figure class="sublist-item">
          <router-link
            to="/leadershiptrousdale"
            class="sublist-link">
            Leadership Trousdale
          </router-link>
        </figure>
        <figure class="sublist-item">
          <router-link
            to="/hotelstudy"
            class="sublist-link">
            Hotel Feasibility
          </router-link>
        </figure>
        <figure class="sublist-item">
          <router-link
            to="/meetings"
            class="sublist-link">
            Meetings
          </router-link>
        </figure>
        <figure class="sublist-item">
          <router-link
            to="/jobs"
            class="sublist-link">
            Career Center
          </router-link>
        </figure>
      </li>

      <li
        class="navlist-item"
        @click="toggleMenu">
        <router-link
          to="/about"
          class="navlist-item-link">
          ABOUT US
        </router-link>
      </li>
      <li
        class="navlist-item"
        @click="toggleMenu">
        <router-link
          to="/business"
          class="navlist-item-link">
          BUSINESS
        </router-link>
      </li>
      <li
        class="navlist-item"
        @click="toggleMenu">
        <router-link
          to="/contact"
          class="navlist-item-link">
          CONTACT
        </router-link>
      </li>
    </ul>
  </main>
</template>
<script>
export default {
  computed: {
    menuOpen: function () {
      return this.$store.state.mobileMenuOpen;
    }
  },
  methods: {
    toggleMenu: function () {
      this.$store.commit('toggleMobileMenu');
    }
  }
};
</script>
<style lang=scss scoped>
  $nav-list-background-color: #ffffff;
  $nav-list-color: #ffffff;
  $nav-item-background-color: #fcf5e8;
  $list-link-color: #3f678d;
  $list-link-hover-color: #84a7c8;
  $list-item-border-color: #3f678d;
  $list-item-font: 'Heebo', sans-serif;

  .mobile-nav-list {
    background-color: $nav-list-background-color;
    height: 0;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    transition: height 1s ease;
    width: 100%;
    z-index: 2;
  }

  .open {
    height: 80%;
    opacity: 1;
    pointer-events: auto;
  }

  .navlist {
    background-color: $nav-list-color;
    height: 100%;
    margin-top: 45px;
    padding: 0;
    width: 100%;
  }

  .navlist-item {
    background-color: $nav-item-background-color;
    border-bottom: 1px solid $list-item-border-color;

  }

  .navlist-item-link {
    color: $list-link-color;
    display: block;
    font-family: $list-item-font;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 1px;
    padding: 16px;
    text-decoration: none;
    transition: color .2s ease;

    &:hover {
      color: $list-link-hover-color;
      text-decoration: underline;
    }
  }

  .sublist-item {
    margin-left: 28px;
    padding-bottom: 16px;
  }

  .sublist-link {
    color: $list-link-color;
    font-family: $list-item-font;
    text-decoration: none;

    &:hover {
      color: $list-link-hover-color;
      text-decoration: underline;
    }
  }
</style>
