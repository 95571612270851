import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import '../public/stylesheets/scss/main.scss';
import 'fullcalendar/dist/gcal.js';
import 'fullcalendar/dist/fullcalendar.css';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faBuilding, faEnvelope, faPhone, faChevronUp, faChevronDown, faTimes, faPlay, faCaretRight, faCaretLeft, faArrowLeft, faCamera, faGlobeAmericas } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faFacebook } from '@fortawesome/free-brands-svg-icons';

library.add(faBuilding, faEnvelope, faPhone, faChevronUp, faChevronDown, faTimes, faPlay, faCaretRight, faCaretLeft, faArrowLeft, faCamera, faGlobeAmericas, faFacebook);

createApp(App).component('font-awesome-icon', FontAwesomeIcon).use(store).use(router).mount('#app');
