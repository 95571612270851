<template>
  <section class="civil-war-banner">
    <div class="banner-text-container">
      Be sure to check out the
      <span class="text-title">
        Battle of Hartsville TN
      </span>
      <span class="text-label">
        Audio Driving Tour
      </span>
    </div>
    <figure class="banner-link">
      <router-link to="/battleofhartsville">
        Click here for more info
      </router-link>
    </figure>
  </section>
</template>
<style scoped lang=scss>
  $header-font: 'Heebo', sans-serif;

  .civil-war-banner {
    background-color: #C73E23;
    color: #fff;
    padding: 1em;
    position: absolute;
    margin-top: 22em;
    font-family: $header-font;
    font-weight: 600;
    text-align: center;
    width: 100%;
  }

  .banner-text-container {
    margin-bottom: .5em;
  }

  .text-title {
    color: #134F80;
    font-size: 16px;
  }

  .text-label {
    font-style: italic;
  }

  .banner-link {
    a {
      color: #FAEBD1;
    }
  }

</style>
