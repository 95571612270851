<template>
  <ul class="nav-list">
    <li
      class="list-item"
      @click="closeMenus">
      <router-link
        to="/welcome"
        class="list-link">WELCOME</router-link>
    </li>
    <li class="list-item community-list-item">
      <router-link
        to="/community"
        class="list-link community-list-link"
        @click="closeMenus">
        COMMUNITY

      </router-link>
      <div
        class="click-chevron"
        @click="toggleCommunityChevron">

        <img
          :class="{rotate: rotateCommunityChevron}"
          class="list-toggle"
          src="/imgs/chevron.png"
          alt="chevron">
      </div>
      <communityDropdown
        :class="{open: openCommunityDropdown}"
        class="community-dropdown"/>
    </li>

    <li
      class="list-item"
      @click="closeMenus">
      <router-link
        to="/about"
        class="list-link">ABOUT</router-link>
    </li>

    <li
      class="list-item"
      @click="closeMenus">
      <router-link
        to="/business"
        class="list-link">BUSINESS</router-link>
    </li>

    <li
      class="list-item"
      @click="closeMenus">
      <router-link
        to="/contact"
        class="list-link">CONTACT</router-link>
    </li>

  </ul>
</template>
<script>
import communityDropdown from './Dropdowns/CommunityDropdownComponent.vue';
export default {
  components: {
    communityDropdown
  },
  data: function () {
    return {
      rotateCommunityChevron: false,
      openCommunityDropdown: false
    };
  },
  mounted () {
    this.$router.beforeEach((to, from, next) => {
      this.closeMenus();
      next();
    });
  },
  methods: {
    toggleCommunityChevron: function () {
      this.rotateCommunityChevron = !this.rotateCommunityChevron;
      this.openCommunityDropdown = !this.openCommunityDropdown;
    },
    closeMenus: function () {
      if (this.rotateCommunityChevron) {
        this.rotateCommunityChevron = false;
      }
      if (this.openCommunityDropdown) {
        this.openCommunityDropdown = false;
      }
    }
  }
};
</script>

<style lang=scss scoped>
  $list-item-font: 'Heebo', sans-serif;
  $list-link-color: #f5d8a3;
  $active-link-color: #ffffff;
  $link-hover-color: #ffffff;

  .nav-list {
    opacity: 0;
    overflow-x: hidden;
    pointer-events: none;
    transition: all .5s ease-in-out;
    height: 0;
    width: 0;
  }

  .list-link {
    color: $list-link-color;
    display: inline-block;
    text-decoration: none;
    transition: 1s ease;

    &:hover {
      color: $link-hover-color;
    }
  }

  .list-item {
    display: inline-block;
    font-family: $list-item-font;
    font-size: 16px;
    font-weight: 800;
    padding: 8px;

  }
  .community-list-item {
    padding-right: 0;
  }

  .community-list-link {
  }

  .router-link-exact-active {
    color: $active-link-color;
    text-decoration: underline;
  }

  .click-chevron {
    box-sizing: border-box;
    display: inline-block;
    height: 13px;
    position: relative;
    width: 24px;
  }

  .community-dropdown {
    display: none;

    &.open {
     display: block;
    }
  }

  .list-toggle {
    display: block;
    height: 6px;
    position: relative;
    margin: 3.5px auto;
    width: 12px;
  }

  .rotate {
    transform: rotate(180deg);
  }

  @media screen and (min-width: 768px) {
    .nav-list {
      opacity: 1;
      pointer-events: auto;
      width: auto;
      height: auto;
    }
  }

</style>
