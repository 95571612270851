import { createStore } from 'vuex';

export default createStore({
  state: {
    mobileMenuOpen: false,
    event: {
      title: '',
      location: '',
      description: '',
      start: '',
      end: ''

    }
  },
  getters: {
    mobileMenuIsOpen: (state) => {
      return state.mobileMenuOpen;
    }
  },
  mutations: {
    toggleMobileMenu: (state) => {
      state.mobileMenuOpen = !state.mobileMenuOpen;
    },
    setTitle: (state, eventTitle) => {
      state.event.title = eventTitle;
    },
    setLocation: (state, eventLocation) => {
      state.event.location = eventLocation;
    },
    setDescription: (state, eventDescription) => {
      state.event.description = eventDescription;
    },
    setStart: (state, eventStart) => {
      state.event.start = eventStart;
    },
    setEnd: (state, eventEnd) => {
      state.event.end = eventEnd;
    }
  },
  actions: {
  },
  modules: {
  }
});
