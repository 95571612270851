<template>
  <main
    :class="{open: menuOpen}"
    class="mobile-nav-page">
    <MobileNavListComponent />
  </main>
</template>

<script>
import MobileNavListComponent from './sections/MobileNavigationListComponent.vue';
export default {
  components: {
    MobileNavListComponent
  },
  computed: {
    menuOpen: function () {
      return this.$store.state.mobileMenuOpen;
    }
  }
};
</script>
<style lang=scss scoped>
  $mobile-nav-background-color: #ffffff;
  $shade-background-color: #000000;

  .mobile-nav-page {

    &::after {
      background-color: $shade-background-color;
      display: block;
      content: "";
      height: 0;
      opacity: 0;
      position: fixed;
      width: 100%;
      z-index: 1;
    }
  }

  .open.mobile-nav-page {

    &::after {
      height: 100%;
      opacity: 0.7;
    }
  }

</style>
