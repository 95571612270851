<template>
  <main class="nav-section">
    <figure
      class="logo"
      @click="checkMobileNav">
      <router-link
        to="/"
        class="logo-link">
        <img
          src="/imgs/HartsvilleChamberLogo.png"
          alt="Hartsvile-Trousdale County Chamber of Commerce logo"
          class="logo-image">
      </router-link>
    </figure>
    <MobileNavComponent />
    <NavListComponent class="navlist" />
  </main>
</template>
<script>
import NavListComponent from './sections/NavigationListComponent.vue';
import MobileNavComponent from '../MobileNavigation/MobileNavToggle.vue';
export default {
  components: {
    NavListComponent,
    MobileNavComponent
  },
  methods: {
    checkMobileNav: function () {
      if (this.$store.state.mobileMenuOpen) {
        this.$store.commit('toggleMobileMenu');
      }
    }
  }
};
</script>
<style lang=scss scoped>
  $nav-background-color: #274159;

  .nav-section {
    align-items: center;
    background-color: $nav-background-color;
    display: grid;
    grid-template-columns: 1fr 3fr;
    padding: 8px;
    position: relative;
    z-index: 2;
  }

  .logo {
    justify-self: start;
    width: 100px;
  }

  .logo-image {
    width: 100%;
  }

  .logo-link {
    display: block;
  }

  .navlist {
    justify-self: end;
  }

  @media screen and ( min-width: 768px) {
    .logo {
      width: 175px;
    }
  };

</style>
