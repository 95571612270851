<template>
  <main class="community-dropdown">
    <ul class="dropdown-list">
      <li class="list-item">
        <router-link
          to="/calendar"
          class="list-link">
          Calendar
        </router-link>
      </li>
      <li class="list-item">
        <router-link
          to="/leadershiptrousdale"
          class="list-link">
          Leadership Trousdale
        </router-link>
      </li>
      <li class="list-item">
        <router-link
          to="/hotelstudy"
          class="list-link">
          Hotel Feasibility
        </router-link>
      </li>
      <li class="list-item">
        <router-link
          to="/meetings"
          class="list-link">
          Meetings
        </router-link>
      </li>
      <li class="list-item">
        <router-link
          to="/jobs"
          class="list-link">
          Career Center
        </router-link>
      </li>
    </ul>
  </main>
</template>
<style lang=scss scoped>
  $dropdown-background-color: #ffffff;
  $list-background-color: #fcf5e8;
  $list-text-color: #274159;
  $border-bottom-color: #274159;

  .community-dropdown {
    background-color: $list-background-color;
    color: $list-text-color;
    margin-top: 26px;
    position: absolute;

    li {
      padding-left: 0;
    }

    .dropdown-list {
      padding-left: 0;
    }
  }

  .list-item {
    border-bottom: 2px solid $border-bottom-color;
    margin-bottom: 8px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .list-link {
    color: $list-text-color;
    display: block;
    padding: 8px;
    text-decoration: none;
  }

</style>
