<template>
  <main class="calendar-section">
    <div class="calendar-content-align">
      <h1 class="title">Community Calendar</h1>
      <section class="calendar-button">
        <router-link
          to="/calendar"
          class="calendar-link">
          View Calendar
        </router-link>

      </section>
    </div>
  </main>
</template>
<style lang=scss scoped>
  $body-background-color: Gainsboro;
  $background-shade-color: whiteSmoke;
  $calendar-font: 'Heebo', sans-serif;
  $title-text-color: #274159;
  $calendar-link-color: #3f678d;
  $calendar-button-background-color: transparentize(#84a7c8, .5);
  $button-hover-color: transparentize(#84a7c8, .3);

  .calendar-section {
    align-items: center;
    background-color: $body-background-color;
    background-image: url(/public/imgs/communityCalendar.jpg);
    height: 400px;
    display: grid;
    font-family: $calendar-font !important;
    justify-items: center;

    &:before {
      content: "";
      background-color: $background-shade-color;
      position: absolute;
      width: 100%;
      height: 400px;
      opacity: .8;
    }

    .calendar-content-align {
      position: relative;
      text-align: center;
    }

    .calendar-button {
      background-color: $calendar-button-background-color;
      border-radius: 5px;
      display: inline-block;
      font-size: 16px;
      margin-top: 16px;
      text-align: center;

      &:active {
        transform: translateY(4px);
      }

      &:hover {
       background-color: $button-hover-color;
      }
    }

    .calendar-link {
      color: $calendar-link-color;
      display: block;
      padding: 8px;
      text-decoration: none;
    }

    .title {
      color: $title-text-color;
      display: relative;
      font-family: $calendar-font !important;
      font-size: 32px !important;
      font-weight: 900;
      letter-spacing: 1.5px;
      z-index: 1;
    }
  }

</style>
