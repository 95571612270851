<template>
  <main class="events-section">
    <h1 class="title">Chamber Events</h1>
    <section class="image-gallery">
      <hgroup class="title-block">
        <h1 class="gallery-title">Recent Event Image Galleries</h1>
      </hgroup>
      <section class="gallery-list">
        <figure class="gallery-list-item">
          <p>
            <router-link to="/santapics">
              Breakfast with Santa
            </router-link>
          </p>
        </figure>
      </section>

    </section>
  </main>
</template>
<style lang=scss scoped>
  $body-background-color: whiteSmoke;
  $title-font: 'Heebo', sans-serif;
  $title-text-color: #ffffff;
  $background-shade-color: #1f3447;
  $highlight-color: darken(#f5d8a3, 20%);
  $event-gallery-link-color: darken(#f5d8a3, 20%);
  $font-color: #274159;
  $link-hover-color: lighten(#274159, 10%);
  $event-item-background-color: transparentize(#023a64, 0.5);
  $banner-background-color:  transparentize(#ebb147, 0.3);

  a {
    color: $font-color;
    font-style: italic;

    &:hover {
      color: $link-hover-color;
      text-decoration: none;
    }
  }
  .events-section {
    background-image: url(/public/imgs/communityThanksgiving.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 400px;

    .title {
      color: $title-text-color;
      font-family: $title-font !important;
      font-size: 32px !important;
      font-weight: 900;
      letter-spacing: 1.5px;
      margin-bottom: 16px;
      padding-top: 32px;
      text-align: center;
    }
  }

  .image-gallery {
    background-color: #ffffff;
    opacity: .6;
    margin: auto;
    max-width: 400px;
    min-height: 250px;
    width: 100%;
  }

  .title-block {
    padding: 8px;
    text-align: center;
  }

  .gallery-title {
    border-bottom: 2px solid $font-color;
    font-size: 20px;
    display: inline-block;

  }

  .gallery-list-item {
    padding: 8px;
    font-size: 18px;

    a {
      color: $event-gallery-link-color;
    }
  }

  @media screen and (min-width: 415px) {
    .image-gallery {
      border-radius: 5px;
    }
  }

</style>
