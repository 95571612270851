<template>
  <main class="mission-section">
    <p class="tagline">
      Serving the Hartsville-Trousdale County Community
    </p>
    <div class="block-alignment">
      <section class="block block1">
        <p class="block-title">Ribbon Cuttings</p>
      </section>
      <section class="block block2">
        <p class="block-title">Monthly Meetings</p>
      </section>
      <section class="block block3">
        <p class="block-title">Leadership Trousdale</p>
      </section>
    </div>
  </main>
</template>
<style lang=scss scoped>
  $body-background-color: #ffffff;
  $mission-font: 'Heebo', sans-serif;
  $block-alignment-background-color: gainsboro;
  $block-background-color: whiteSmoke;
  $block-title-text-color: #ffffff;
  $block-title-font: 'Heebo', sans-serif;
  $tagline-background-color: #ffffff;
  $tagline-font: 'Heebo', sans-serif;
  $tagline-text-color: #274159;
  $tagline-background-color: lighten(#f5d8a3, 10%);

  .mission-section {
    align-items: center;
    background-color: $body-background-color;
    display: grid;
    grid-template-rows: 1fr 11fr;
    min-height: 500px;
  }

  .tagline {
    align-items: center;
    background-color: $tagline-background-color;
    color: $tagline-text-color;
    background-color: $tagline-background-color;
    display: grid;
    font-family: $tagline-font;
    font-size: 24px;
    font-style: italic;
    font-weight: 600;
    height: 100%;
    line-height: 1.5;
    margin-bottom: 0;
    text-align: center;
  }

  .block-alignment {
    align-items: center;
    background-color: $block-alignment-background-color;
    display: grid;
    grid-template-rows: 1fr 1fr 1fr;
    height: 100%;
    justify-items: center;
    margin: 32px 0;
  }

  .block {
    align-items: center;
    background-color: $block-background-color;
    display: grid;
    justify-items: center;
    height: 315px;
    width: 315px;

    margin: 8px 0;

    &:before {
      background-color: black;
      content: "";
      opacity: .5;
      position: absolute;
      height: 315px;
      width: 315px;
    }
  }

  .block1 {
    background-image: url(/public/imgs/ribbonCutting.jpg);
    background-position: center;
    background-size: cover;
  }

  .block2 {
    background-image: url(/public/imgs/meeting.jpg);
    background-position: center;
    background-size: cover;
  }

  .block3 {
    background-image: url(/public/imgs/leadershipSupremeCourt.jpg);
    background-position: center;
    background-size: cover;
  }

  .block-title {
    color: $block-title-text-color;
    font-family: $block-title-font;
    font-size: 24px;
    font-weight: 900;
    position: relative;
    z-index: 1;
  }

  @media screen and (min-width: 768px) {
    .block {
      height: 315px;
      width: 500px;

      &:before {
        background-color: black;
        content: "";
        opacity: .5;
        position: absolute;
        height: 315px;
        width: 500px;
      }
    }

  }

  @media screen and (min-width: 900px) {
    .block-alignment {
      grid-template-columns: 1fr 1fr 1fr;
      grid-template-rows: 1fr;
    }

    .block {
      height: 315px;
      width: 300px;

      &:before {
        background-color: black;
        content: "";
        opacity: .5;
        position: absolute;
        height: 315px;
        width: 300px;
      }
    }

    .mission-section {
      grid-template-rows: 1fr 6fr;
    }
  }

</style>
