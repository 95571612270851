<template>
  <header class="header">
    <MobileNavComponent />
    <NavComponent />
    <transition name="fade">
      <router-view/>
    </transition>
    <FooterComponent />
  </header>
</template>
<script>
import MobileNavComponent from './components/Shared/MobileNavigation/MobileNav.vue';
import NavComponent from './components/Shared/Navigation/NavigationComponent.vue';
import FooterComponent from './components/Shared/FooterComponent.vue';

export default {
  components: {
    NavComponent,
    FooterComponent,
    MobileNavComponent
  }
};
</script>
<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
.header {
  .fade-enter-active, .fade-leave-active {
    transition-property: opacity;
    transition-duration: .25s;
  }

  .fade-enter-active {
    transition-delay: .25s;
  }

  .fade-enter, .fade-leave-active {
    opacity: 0
  }
}
</style>
