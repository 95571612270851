<template>
  <main class="home-page">
    <HeroComponent />
    <MissionComponent />
    <EventsComponent />
    <CommunityCalendarComponent />
    <HistoryComponent />
  </main>
</template>

<script>
import HeroComponent from './sections/HeroComponent.vue';
import MissionComponent from './sections/MissionComponent.vue';
import EventsComponent from './sections/EventsComponent.vue';
import CommunityCalendarComponent from './sections/CommunityCalendar.vue';
import HistoryComponent from './sections/HistoryComponent.vue';
export default {
  components: {
    HeroComponent,
    MissionComponent,
    EventsComponent,
    CommunityCalendarComponent,
    HistoryComponent
  }
};
</script>

<style lang=scss scoped>
  $header-font: 'Heebo', sans-serif;
  $body-font: 'Roboto Slab', serif;

  .title {
    font-family: $header-font;
    font-weight: 900;
  }

  .body {
    font-family: $body-font;
    font-weight: 300;
  }
</style>
